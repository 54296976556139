table.table {
  margin-bottom: 0;

  &.table-hover {
    tbody {
      tr {
        &:hover {
          background-color: #ebecee;
        }
      }
    }
  }

  td, th {
    vertical-align: middle;
  }


  .selection-cell {
    text-align: center;
  }

  td.react-bootstrap-table-editing-cell {
    padding: 0 !important;
  }

  td,
  td.react-bootstrap-table-editing-cell {
    border-color: #ececec;
    padding: 0.15rem 0.3rem;
    height: 40px;
    input.form-control {
      border: none;
      border-radius: 0;
      height: 100%;
      box-shadow: none;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    .input-group {
      border: none;
      height: 100%;

      .input-group-text {
        border: none;
        border-radius: 0;
      }
    }
  }

  td.bg-danger {
    background-color: #f7e2e2 !important;
    color: #2a2a2a;
  }

  td.bg-warning {
    background-color: #ffe9ad !important;
    color: #2a2a2a;
  }

  tfoot {
    th {
      border-color: #e0e3e6;
      border-top: 2px solid #797e91;
      background: #E9ECEF;
      color: #252525;
      padding: 0.75rem 0.3rem;
    }
  }

  thead {
    tr {
      th {
        text-overflow: ellipsis;
        white-space: nowrap !important;
        overflow: hidden !important;
        z-index: 4;
        background-color: #F4F5F7;
        border-color: #ececec;
        text-transform: uppercase;
        padding: 0.75rem 0.3rem;
        font-size: 12px;
      }
    }
  }

  tr {
    &.tr-1,
    &.tr-1 > th,
    &.tr-1 > td {
      background-color: #F1F1F1;
      color: #23282c;
    }

    &.tr-2,
    &.tr-2 > th,
    &.tr-2 > td {
      background-color: #e0e4e7;
      color: #23282c;
    }

    &.tr-footer,
    &.tr-footer > th,
    &.tr-footer > td {
      background-color: #c8ced3;
      color: #23282c;
    }
  }

  tr.bg-danger {
    background-color: #EF8D97 !important;
  }

  .row-select-btn {
    line-height: 17px;
    padding-top: 0;
    padding-bottom: 0;
  }

  &.table-sm th,
  &.table-sm td {
    padding: 0.3rem 0.3rem;
    height: 32px;
  }
  @media (max-width: 575.98px) {
    .table-sm th,
    .table-sm td {
      padding: 0.1rem;
    }
  }
  @media (max-width: 1199.98px) {
    thead {
      tr {
        th {
          width: 10vw;
        }
      }
    }
  }
  @media (max-width: 991.98px) {
    td {
      height: 32px;
    }

    thead {
      tr {
        th {
          padding: 0.3rem 0.3rem;
          width: 35vw;
        }
      }
    }

    tfoot {
      th {
        padding: 0.3rem 0.3rem;
      }
    }
  }

  @media (max-width: 767.98px) {
    thead {
      tr {
        th {
          width: 45vw;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    thead {
      tr {
        th {
          width: 60vw;
        }
      }
    }
  }
}

@media print {
  .table td {
    background-color: transparent !important;
  }
}

.react-bootstrap-table-pagination {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0.5rem;

  >* {
    width: 50% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}