.table-with-overlay {
  position: relative;

  .overlay {
    position: absolute;
    width: 100%;
    height: calc(100% - 45px);
    background-color: rgba(0,0,0,0.3);
    top: 29px;
    z-index: 4;
  }
}