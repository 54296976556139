.payu-card-container {
  text-align: center;
  width: 420px;
  margin: 20px auto 10px;
  display: block;
  border-radius: 5px;
  box-sizing: border-box;
  color: #ffffff;

  .card-container {
    width: 100%;
    margin: 0 auto;
    border-radius: 6px;
    padding: 10px;
    background: #2a2a2a;
    text-align: left;
    box-sizing: border-box;
  }

  .card-container aside {
    padding-bottom: 6px;
  }

  .payu-card-form {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
  }

  .card-details {
    clear: both;
    overflow: auto;
    margin-top: 10px;
  }

  .card-details .expiration {
    width: 50%;
    float: left;
    padding-right: 5%;
  }

  .card-details .cvv {
    width: 45%;
    float: left;
  }

  .response-success {
    color: #438F29;
  }

  .response-error {
    color: #990000;
  }
}