.tec-rmi-body-graphics {
  max-height: 75vh;

  &.tec-rmi-body-graphics-vehicle,
  &.tec-rmi-body-graphics-main-group {
    svg {
      rect, path {
        fill: #ff0000;
      }
    }
  }

  height: 100%;
}

.tec-rmi-svg {
  position: relative;

  #ToolTip, #toolTip {
    position: fixed !important;
  }

  g[onclick] {
    cursor: pointer;
  }
  
  a {
    color: #20a8d8;

    &[onclick] {
      cursor: pointer;
    }
  }

  span#ToolTip {
    position: fixed !important;
  }

  &.svg-styled {
    svg {
      width: 100% !important;
      max-width: 100%;
      height: 70vh !important;
      max-height: 100%;
      border: 2px solid #8b929a;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
  }
}

.modules-list {
  svg {
    max-width: 100%;
    max-height: 100%;
  }
}