@import "node_modules/@coreui/coreui-pro/scss/variables";
// Variable overrides

$input-btn-focus-width: 0;
$font-size-base: 0.8rem;
$badge-font-size: 85%;
$modal-dialog-margin: 0;


//Tables
$table-hover-bg: rgba($primary, .075);

//Button
$btn-focus-width: 0;