.react-bootstrap-table {
  margin-top: 0.5rem;

  &.table-scroll-x {
    overflow-x: auto;
  }

  table {
    thead {
      tr {
        th.sortable.column-sorted {
          background-color: #cbcbcb;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .react-bootstrap-table {
    margin-top: 3px;
  }
}

@media print {
  .react-bootstrap-table {
    overflow: initial !important;
    max-height: initial !important;
    overflow-x: initial !important;
  }
}

.app-table {
  .table-wrapper {
    flex-grow: 1 !important;
    height: 100px;
    min-height: 35vh;
    display: flex;
    flex-direction: column;

    &.no-scroll {
      height: auto;
      min-height: auto;
    }
  }

  .react-bootstrap-table {
    overflow: auto;
    max-height: 100%;
    position: relative;
    overflow-x: auto;

    > .table-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      padding-top: 30px;
      font-size: 1.3rem;

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0000001c;
        top: 0;
        left: 0;
      }
    }

    &.loading-data {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #0000001c;
        top: 0;
        left: 0;
        z-index: 5;
      }
    }

    table {
      margin-bottom: 0;
    }

    th {
      position: sticky;
      top: -1px;
      border-top: none;
    }
  }

  .react-bootstrap-table-page-btns-ul {
    margin-bottom: 0;
  }

  @media print {
    .table-wrapper {
      height: auto !important;
    }
    .react-bootstrap-table-pagination {
      display: none;
    }
  }
}

@media (max-width: 991.98px) {
  .react-bootstrap-table {
    > .table-overlay {
      &::before {
        width: 9999% !important;
      }
    }
    &.loading-data {
      &::before {
        width: 9999% !important;
      }
    }
  }
}

//@media (max-width: 991.98px) {
//  .app-table {
//    .table-wrapper {
//      margin-top: 0.2rem;
//    }
//  }
//}
