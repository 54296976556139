#files-wrapper {
  .file {
    width: 200px;
    height: 200px;
    margin: 0 12px 12px 0;
    position: relative;

    .overlay {
      display: none;
    }

    &.uploading {
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: block;

        .bg {
          border-radius: 0.25rem;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          opacity: 0.35;
          background: #0a0a0a;
        }
      }
    }

    .remove-button {
      position: absolute;
      top: 2px;
      right: 2px;
    }

    >.inner {
      cursor: pointer;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 0px 0px 6px #bfbfbf;
      transition: 0.15s ease-in-out;
      position: relative;

      &:hover {
        box-shadow: 0px 0px 8px #5d5d5d;

        > .bottom-details {
          max-height: 80%;
          transition: max-height 0.25s ease-in;
        }

        ~ .comment-icon-position {
          display: none;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }

      > .bottom-details {
        max-height: 0;
        transition: max-height 0.15s ease-out;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #0000008a;
      }
    }

    .comment-icon-position {
      position: absolute;
      bottom: 6px;
      left: 95%;
      transform: translateX(-50%);
    }
  }
}

.popover-body {
  #files-wrapper {
    .file {
      width: 60px;
      height: 60px;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    z-index: 1051 !important;
  }
}