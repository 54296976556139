.modal {
  width: 100vw;

  .modal-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    align-items: center;

    .modal-title {
      width: 100%;
      max-width: calc(100% - 26px);
    }

    .close {
      span {
        font-size: 1.3em;
      }
    }
  }

  .modal-dialog.height-100vh {
    overflow-y: initial !important;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 100%;
    max-height: 100%;

    @media (max-width: 991.98px) {
      margin-top: 0;
      margin-bottom: 0;
      //height: 100vh;
    }

    .modal-content {
      height: 100%;

      .modal-body {
        max-height: 90%;
        overflow-y: auto;
      }
    }
  }
}

@mixin modalW100() {
  max-width: none !important;
  overflow-y: initial !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 100%;
  max-height: 100%;

  .modal-content {
    border-radius: 0 !important;
    max-height: 100% !important;

    .modal-body {
      max-height: 90%;
      overflow-y: auto;
    }
  }
}

.modal-dialog {
  &.modal-w-100 {
    @include modalW100;
  }
}

@media (max-width: 991.98px) {
  .modal {
    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 0.5rem;
    }

    .modal-header {
      .close {
        padding: .25rem;
        margin: 0;
      }
    }
  }

  .modal-dialog {
    &.modal-w-md-100 {
      width: 100vw;
      max-width: 100vw;
      overflow-y: initial !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      height: 100vh !important;

      .modal-content {
        max-height: 100vh !important;
        height: 100vh !important;
        border-radius: 0 !important;

        .modal-body {
          max-height: 90%;
          overflow-y: auto;
        }
      }
    }
  }
}

.modal-dialog-scrollable {
  max-height: 100%;

  .modal-content {
    max-height: 100%;
  }
}

@media (min-width: 576px) {
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);

    .modal-content {
      max-height: calc(100vh - 3.5rem);
    }
  }
}

@media (max-width: 575.98px) {
  .modal-dialog {
    height: 100%;
  }
}