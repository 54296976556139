.supplier-img-list {
  .supplier {
    border-width: 2px!important;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100px;

    .logo-img {
      max-height: 45px;
      max-width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }

    &:not(:hover):not(.selected) {
      .logo-img {
        &.grayscale {
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
          opacity: 0.4;
        }
      }
    }
  }
}