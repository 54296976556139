@media print {
  a {
    text-decoration: none !important;
  }

  .card.report-printable {
    border: none;
    > .card-header,
    > .card-footer {
      display: none;
    }

    > .card-body {
      padding: 0;
    }

    .card.report-settings {
      display: none;
    }

    .report-presentation {
      > .card {
        border: none;
      }
    }
  }
}

.card.report-printable.printing {
  .report-presentation {
    width: 1000px !important;
  }
  .react-bootstrap-table {
    overflow: visible;
  }
}