.card-body:has(> .nav-tabs) {
  padding: 0px;

  > .nav-tabs {
    > .nav-item {
      margin-bottom: 0 !important;
      &:first-child {
        > .nav-link {
          border-left: none;
        }
      }
      &:last-child {
        margin-right: .25rem;
      }
      > .nav-link {
        border-top: none;
        border-radius: 0;

        &:hover, &.active {
          color: $dark !important;
        }
        &.active {
          background-color: $card-cap-bg;
          border-color: $border-color $border-color $border-color;
        }
      }
    }
  }

  > .tab-content {
    border-left: none;
    border-right: none;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    > .tab-pane:has(> .accordion > .card) {
      padding: 0 !important;

      > .accordion > .card {
        &:first-child {
          border-top: none;
        }
        &:last-child {
          margin-bottom: 0;
          .card-header {
            border-bottom: none !important;
          }
        }
        border-left: none;
        border-right: none;
        border-radius: 0 !important;
      }
    }
  }
}