.modal-dialog {
  .table-fixed-header {
    //max-height: calc(100vh - 205px);
  }
}

.table-fixed-header {
  //max-height: calc(100vh - 350px);
  min-height: 220px;
  max-height: 100%;
  overflow-y: scroll;

  table {
    text-align: left;
    position: relative;
    border-collapse: collapse;
  }
  th, td {
    padding: 0.25rem;
  }
  th {
    position: sticky;
    top: -5px;
  }
}