.tooltip.show {
  z-index: 1049;
  opacity: 1;
}

.modal,
.tooltip-on-modal {
  .tooltip.show {
    z-index: 1051;
  }
}

.tooltip-inner {
  background-color: #26547C;
  text-align: left;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #26547C;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #26547C;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #26547C;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #26547C;
}

@media print {
  .tooltip {
    display: none !important;
  }
}
