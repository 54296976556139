@import '../variables';

:root {
  --fc-event-bg-color: #FEF1CE;
  --fc-event-border-color: #FEF1CE;
  --fc-event-text-color: #212529;
}

.fc-h-event .fc-event-main {
  a {
    color:var(--fc-event-text-color, #fff);
  }
}

.fc {
  .fc-orientation-button {
    background: none;
    border: none;
  }

  .fc-view.is-loading {
    &::before {
      display: block;
      content: 'Trwa ładowanie danych...';
      font-size: 2.4em;
      color: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      background: #00000040;
      z-index: 4;
      text-align: center;
      padding-top: 100px;
    }
  }
}

.fc {
  .fc-col-header {
    tr {
      .fc-timegrid-axis,
      .fc-col-header-cell {
        padding: 13px 5px;
        background-color: #F4F5F7;
        text-transform: uppercase;
      }
    }
    tr:nth-child(2) {
      .fc-timegrid-axis,
      .fc-col-header-cell {
        padding: 10px 5px;
        background-color: #fff;
        font-size: 0.65rem;
      }
    }
  }

  .fc-timegrid-col {
    &.fc-day-today {
      background-color: #f2f8ff !important;
    }
  }

  .fc-timegrid-slot-label-frame {
    font-weight: bold;
  }

  .table-bordered th, .table-bordered td {
    border-color: #edeeee !important;
  }

  .fc-daygrid-day-bg {
    min-height: 110px;
  }

  .fc-v-event {
    background-color: #FEF1CE !important;
    border-color: #d7dde3 !important;
    color: #212529 !important;
  }

  //.fc-resourceTimeGridWeek-view {
  //  .fc-timegrid-col {
  //    &:not(.fc-day-today) {
  //      &.fc-day-mon,
  //      &.fc-day-wed,
  //      &.fc-day-fri {
  //        background-color: #f9f9f9;
  //      }
  //    }
  //  }
  //}
}

.fc-resources-count-1 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(2n) {
      background-color: #f9f9f9;
    }
  }
}

.fc-resources-count-2 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(4n+2),
    .fc-timegrid-col.fc-day:nth-child(4n+3) {
      background-color: #f9f9f9;
    }
  }
}
.fc-resources-count-3 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(6n+2),
    .fc-timegrid-col.fc-day:nth-child(6n+3),
    .fc-timegrid-col.fc-day:nth-child(6n+4) {
      background-color: #f9f9f9;
    }
  }
}
.fc-resources-count-4 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(8n+2),
    .fc-timegrid-col.fc-day:nth-child(8n+3),
    .fc-timegrid-col.fc-day:nth-child(8n+4),
    .fc-timegrid-col.fc-day:nth-child(8n+5) {
      background-color: #f9f9f9;
    }
  }
}
.fc-resources-count-5 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(10n+2),
    .fc-timegrid-col.fc-day:nth-child(10n+3),
    .fc-timegrid-col.fc-day:nth-child(10n+4),
    .fc-timegrid-col.fc-day:nth-child(10n+5),
    .fc-timegrid-col.fc-day:nth-child(10n+6) {
      background-color: #f9f9f9;
    }
  }
}
.fc-resources-count-6 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(12n+2),
    .fc-timegrid-col.fc-day:nth-child(12n+3),
    .fc-timegrid-col.fc-day:nth-child(12n+4),
    .fc-timegrid-col.fc-day:nth-child(12n+5),
    .fc-timegrid-col.fc-day:nth-child(12n+6),
    .fc-timegrid-col.fc-day:nth-child(12n+7) {
      background-color: #f9f9f9;
    }
  }
}
.fc-resources-count-7 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(14n+2),
    .fc-timegrid-col.fc-day:nth-child(14n+3),
    .fc-timegrid-col.fc-day:nth-child(14n+4),
    .fc-timegrid-col.fc-day:nth-child(14n+5),
    .fc-timegrid-col.fc-day:nth-child(14n+6),
    .fc-timegrid-col.fc-day:nth-child(14n+7),
    .fc-timegrid-col.fc-day:nth-child(14n+8) {
      background-color: #f9f9f9;
    }
  }
}
.fc-resources-count-8 {
  .fc-resourceTimeGridWeek-view {
    .fc-timegrid-col.fc-day:nth-child(16n+2),
    .fc-timegrid-col.fc-day:nth-child(16n+3),
    .fc-timegrid-col.fc-day:nth-child(16n+4),
    .fc-timegrid-col.fc-day:nth-child(16n+5),
    .fc-timegrid-col.fc-day:nth-child(16n+6),
    .fc-timegrid-col.fc-day:nth-child(16n+7),
    .fc-timegrid-col.fc-day:nth-child(16n+8),
    .fc-timegrid-col.fc-day:nth-child(16n+9) {
      background-color: #f9f9f9;
    }
  }
}
