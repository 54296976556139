@media print {
  body {
    background-color: #fff;
    -webkit-print-color-adjust: exact;
  }

  a {
    color: $dark;
  }

  .aside-menu {
    border-left: none;
  }

  .app-header {
    display: none !important;
  }
  .app-body {
    padding-top: 0 !important;
    height: auto !important;
    overflow-x: inherit;

    >.app-body-wrapper {
      >.app-body-inner {
        >.view-header {
          display: none !important;
        }
      }
    }
  }

  .back-link {
    display: none !important;
  }
}