.ladda-label,
.btn {
  &:focus {
    box-shadow: none;
  }

  i {
    &.fa,
    &.fas,
    &.far,
    &.fab {
      line-height: 21px;
    }
  }

  &.btn-sm {
    i {
      &.fa,
      &.fas,
      &.far,
      &.fab {
        line-height: 19px;
      }
    }
  }

  span:not(:first-child) {
    margin-left: .5rem;
    white-space: nowrap;
  }
}

.btn.ladda-button {
  .ladda-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
}

/* in modal header */
.modal {
  .modal-header {
    .btn {
      padding: .15rem .5rem;
    }
  }
}