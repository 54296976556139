.DateRangePicker {
  .DateRangePickerInput__withBorder {
    border-radius: $input-border-radius;
    border-color: $input-border-color;
  }
  .DateRangePickerInput {
    white-space: nowrap;
    .DateInput {
      width: 87px;

      .DateInput_input {
        padding: 4px 5px 3px;
        font-size: 13px;
        text-align: center;

        &.DateInput_input__focused {
          border-bottom-color: #30a0e3;
        }
      }
    }

    .DateRangePickerInput_clearDates {
      padding: 5px;
      margin: 0 3px 0 0;
      top: 45%;

      &.DateRangePickerInput_clearDates_default:focus,
      &.DateRangePickerInput_clearDates_default:hover {
        background: none;
      }

      .DateRangePickerInput_clearDates_svg {
        height: 9px;
        width: 10px;
      }
    }
  }

  .DateRangePickerInput_arrow {
    vertical-align: top;
    padding-top: 3px;

    .DateRangePickerInput_arrow_svg {
      width: 15px;
      height: 15px;
    }
  }

  .DateRangePicker_picker {
    z-index: 5;

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background-color: #30a0e3;
      border-color: #30a0e3;
    }

    .CalendarDay__selected_span:active,
    .CalendarDay__selected_span:hover {
      background: lighten(#30a0e3, 10);
      border-color: lighten(#30a0e3, 3);
    }

    .CalendarDay__selected_span {
      background: lighten(#30a0e3, 23);
      border-color: lighten(#30a0e3, 15);
    }

    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      background: lighten(#30a0e3, 35);
      border-color: lighten(#30a0e3, 25);
    }

    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover {
      color: darken(#30a0e3, 10);
    }
  }
}