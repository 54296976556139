.repair-order-damage-element-number {
  border: 3px solid #E85757;
  border-radius: 100px;
  font-weight: bold;
  text-align: center;
  line-height: 21px;
  width: 28px;
  height: 28px;
  font-size: 1em;
  color: #fff;
  background-color: #E85757;
  cursor: default !important;

  &.damage-on-img {
    margin-left: -14px;
    margin-top: -14px;
  }
}