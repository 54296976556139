.tree-menu {
  &.list-group {
    &.additional-left-padding {
      > .list-group {
        > .list-group-item {
          padding-left: 2.35rem;
        }
        > .list-group {
          > .list-group-item {
            padding-left: 3.75rem;
          }
          > .list-group {
            > .list-group-item {
              padding-left: 5rem;
            }
            > .list-group {
              > .list-group-item {
                padding-left: 6.25rem;
              }
              > .list-group {
                > .list-group-item {
                  padding-left: 7.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}