.d-flex.flex-two-cols {
  > div {
    width: 50%;
    margin-bottom: 0.5rem;
  }
  > div:nth-child(odd) {
    padding-right: 0.25rem;
  }
  > div:nth-child(even) {
    padding-left: 0.25rem;
  }
}