.worker-label.img-avatar {
  &.worker-label-size-sm {
    min-width: 20px;
    width: 20px;
    min-height: 20px;
    height: 20px;
  }
  &.worker-label-size-md {
    min-width: 28px;
    width: 28px;
    min-height: 28px;
    height: 28px;
  }
}

.workers-label-group {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .worker-label.img-avatar:not(:first-child) {
    &.worker-label-size-sm {
      margin-left: -10px;
    }
    &.worker-label-size-md {
      margin-left: -15px;
    }
  }
}
