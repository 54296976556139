/** prefix **/
.react-select__control {
  min-height: 35px !important;
  flex-wrap: nowrap !important;
  border-color: #ced4da !important;

  &:hover {
    border-color: #ced4da !important;
  }
  &.react-select__control--is-focused {
    border-color: #8ad4ee !important;
    box-shadow: none;
  }
  .react-select__multi-value {
    background-color: #E9ECEF;

    .react-select__multi-value__remove {
      cursor: pointer;
    }
  }

  &.has-error {
    border-color: $danger !important;
  }
}
.react-select__single-value,
.react-select__placeholder {
  top: 49% !important;
}
.react-select__single-value {
  width: calc(100% - 15px);
}
.react-select__menu {
  box-shadow: none !important;
  border: 1px solid #ced4da;
  margin-top: 4px !important;
  z-index: 10 !important;
}
.react-select__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .react-select__option {
    &:not(:last-child) {
      border-bottom: 1px solid #ced4da;
    }

    &.react-select__option--is-focused {
      background-color: #ebecee;
    }

    &.react-select__option--is-selected {
      background-color: #D4E6FB;
      color: #2a2a2a;
      font-weight: bold;
    }
  }
}
.react-select__menu-portal {
  z-index: 1051 !important;
}

.react-select__indicator {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;

  svg {
    display: none;
  }
}
.react-select__group-heading {
  font-size: 1em !important;
  padding: 0 !important;
  margin: 0 !important;
}
.react-select__group {
  padding: 0 !important;
}

.react-select__clear-indicator {
  padding: 8px 2px !important;

  &::before {
    box-sizing: border-box;
    content: '\f00d';
    padding: 2px 5px 0;
    color: #2a2a2a;
    cursor: pointer;
  }
}

.react-select__dropdown-indicator {
  &::before {
    box-sizing: border-box;
    content: '\f0d7';
    padding: 0 5px;
    font-size: 1rem;
    color: #2a2a2a;
    cursor: pointer;
  }
}

.react-select__control {
  .react-select__value-container:not(.react-select__value-container--is-multi) {
    flex-grow: 1;

    > div {
      flex-grow: 1;

      .react-select__input {
        display: flex !important;
        flex-grow: 1;

        input {
          flex-grow: 1;
        }
      }
    }
  }
}



/* in tables */
.react-bootstrap-table {
  .react-select__control {
    border: none !important;
    border-radius: 0 !important;

    .react-select__indicator-separator,
    .react-select__dropdown-indicator {
      display: none;
    }
  }
}

/* in modal header */
.modal {
  .modal-header {
    .react-select__indicator-separator,
    .react-select__dropdown-indicator {
      display: none;
    }
  }
}

/* in input group */
.input-group {
  :first-child {
    .react-select__control {
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }
  }

  .react-select__control {
    .react-select__dropdown-indicator {
      &::before {
        padding: 0;
      }
    }
  }

  .input-group-append {
    .react-select__control {
      background-color: #ebecee;
      border-radius: 0 $input-border-radius $input-border-radius 0;
    }
  }

  .input-group-prepend {
    .react-select__control {
      background-color: #ebecee;
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }
  }

  .input-group-append:not(:last-child) {
    .react-select__control {
      border-radius: 0;
    }
  }

  &.input-group-sm {
    .react-select__control {
      height: 29px;
      min-height: 29px !important;

      .react-select__indicator {
        padding: 6px !important;
      }

      .react-select__single-value,
      .react-select__placeholder {
        top: 48% !important;
      }
    }
  }
}
