/* width */
@media( max-width : 991.98px ) {
  ::-webkit-scrollbar {
    width: 0;
  }
}
@media( min-width : 992px ) {
  ::-webkit-scrollbar {
    width: 12px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #ebecee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ced4da;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ced4da;
}

.scrollbar-container {
  scroll-behavior: smooth;
}
