$body-color: #1B1E21;
$body-bg: #ebecee;

$theme-colors: (
    "primary":    #26547C,
    "secondary": #8b929a,
    "success":    #13A594,
    "info":       #5394cc,
    "warning":    #e67c27,
    "danger":     #D92750,
    "light":      #D8D8D8,
    "dark":       #2A2A2A,
);

$blue:    #26547C !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #D92750 !default;
$orange:  #e67c27 !default;
$yellow:  #f3bc18 !default;
$green:   #13A594 !default;
$teal:    #0a918a !default;
$cyan:    #33bcd8 !default;

$input-focus-box-shadow: none;
$input-border-color: #ced4da;
$input-color: #212529;
$input-focus-color: #212529;
$input-group-addon-bg: #ebecee;
$input-group-addon-color: #212529;

$tooltip-bg: #0096D9;
$tooltip-arrow-color: #0096D9;
$tooltip-opacity: 1;

$btn-disabled-opacity: 0.45;

$modal-backdrop-bg: #2a2a2a;
$modal-backdrop-opacity: 0.35;
$dropdown-link-hover-bg: #ebecee;
$dropdown-border-color: #CED4DA;
$dropdown-link-active-bg: #ebecee;
$dropdown-link-active-color: #23282c;
$dropdown-border-color: #ced4da;

$pagination-focus-box-shadow: none;
$pagination-color: #2a2a2a;
$pagination-border-color: #dee2e6;
$pagination-active-color: #2a2a2a;
$pagination-active-bg: #ebecee;
$pagination-active-border-color: #dee2e6;
$pagination-hover-bg: #ebecee;
$pagination-hover-color: #2a2a2a;
$pagination-hover-border-color: #dee2e6;

$modal-content-border-color: #ced4da;
$modal-content-border-width: 2px;
$modal-header-border-color: #E9ECEF;
$modal-footer-border-color: #E9ECEF;

$nav-tabs-link-active-color: #0096D9;

$card-border-color: #ced4da;
$card-cap-bg: #F4F5F7;

$popover-header-bg: #2a2a2a;
$popover-header-color: $white;
$popover-max-width: 500px;
$popover-border-color: #2a2a2a;
$popover-arrow-color: #2a2a2a;

.tab-content {
  border-color: #ced4da !important;
  border-radius: 0.25rem !important;
}

@import "~@coreui/coreui-pro/scss/coreui";
@import "nav-menu";




@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
  url("./fonts/ubuntu/Ubuntu-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "UbuntuBold";
  src: local("UbuntuBold"),
  url("./fonts/ubuntu/Ubuntu-Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  outline: none !important;
}

html, body {
  height: 100%;
}

body {
  font-family: Ubuntu;
  cursor: auto !important;

  > #root {
    height: 100%;

    > .app {
      height: 100%;
      min-height: 100%
    }
  }
}

#chat-application {
  right: 0 !important;
  bottom: 0 !important;
}

.header-fixed {
  .app.bottom-info {
    .app-body {
      //height: calc(100vh - 61px - 38px - 60px);
    }
    footer#footer {
      //margin-bottom: 60px;
    }
  }

  .app-body {
    margin-top: 61px;
    //height: calc(100vh - 61px - 38px);
    display: flex;
    flex-direction: column;

    >.app-body-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0 5px 0 5px;

      > .app-body-inner {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        > .view-header {
          padding: 0.4rem 2px;
          height: 41px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .view-title {
            text-transform: uppercase;
            font-weight: bold;
            white-space: nowrap;
          }
        }

        > .card {
          border-left: none;
          border-right: none;
          border-radius: 0;
          flex-grow: 1;
          margin-bottom: 0;
        }

        >.back-link {
          padding-top: 0.75rem;
        }

        @media (max-width: 991.98px) {
          >.back-link {
            padding-top: 0.25rem;
          }
        }
      }
    }
  }

  .faker-data-info {
    //bottom: 0;
    //left: 0;
    height: 60px;
    //z-index: 10;
    //position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;

    span {
      font-size: 1.8rem;
    }
  }
}

footer#footer {
  border-top: 1px solid $border-color;
  height: 38px;
  padding: 0 15px;
}

@media( max-width : 991.98px ) {
  .header-fixed {
    footer#footer {
      //height: 35px;
      padding: 0 0;
    }
    .app.bottom-info {
      .app-body {
        //height: calc(100vh - 49px - 35px - 45px);
      }

      footer#footer {
        //margin-bottom: 45px;
      }
    }
  }
}

.img-logo {
  border-radius: $btn-border-radius;
}

.row {
  margin-left: -5px;
  margin-right: -5px;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 991.98px) {
  .header-fixed {
    .app-body {
      margin-top: 49px;
      //height: 100px;
    }

    .faker-data-info {
      height: 45px;
      line-height: 18px;

      span {
        font-size: 1.1rem;
      }
    }
  }

  .row {
    margin-left: -4px;
    margin-right: -4px;
  }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.main .container-fluid {
  padding-top: 0.4rem !important;
}

.btn {
  text-transform: uppercase;
}

.pagination {
  .page-item.active {
    font-weight: bold;
  }
}

.modal {
  .modal-title {
    width: 100%;
  }
}

.card-header-view {
  padding: 0.4rem 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card {
  .card-header {
    text-transform: uppercase;
    font-weight: bold;
  }
}

a,
a:hover,
.dropdown-item i {
  color: #2a2a2a;
}

.nav-tabs {
  z-index: 1;
  border-bottom: none;

  .nav-item {
    .nav-link {
      &:hover {
        color: #0096D9;
        border-bottom: none;
      }
    }
  }
}

input {
  &.form-control {
    &.bg-danger {
      background-color: #f7e2e2 !important;
      color: #2a2a2a;
    }
  }
}

.repair-order-status-table {
  height: 100%;
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.7rem;
  font-weight: bold;

  .status-name {
    width: calc(100% - 10px);
    overflow: hidden;
  }
}

//// Here you can add other styles
@import "own/button";
//@import "own/draggable";
@import "own/scrollbar";
@import "own/supplier";
@import "own/app-table";
@import "own/payu-card-tokenization";
@import "own/table";
@import "own/card-control-table";
@import "own/react-select";
@import "own/modal";
@import "own/files";
@import "own/card-nav-tabs";
//@import "own/switch";
@import "own/table-fixed-header";
@import "own/tooltip";
@import "own/popover";
@import "own/list-group";
//@import "own/card-hover";
@import "own/report";
@import "own/media-print";
@import "own/date-range-picker";
//@import "own/react-datepicker";
@import "own/full-calendar";
@import "own/table-with-overlayer";
@import "own/layout";
@import "own/flex-settings-columns";
@import "own/toastify";
@import "own/tec-rmi";
@import "own/input-autocomplete";
@import "own/worker";
@import "own/repair-order-damage";
//
@media print {
  body {
    margin: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    width: 28cm;
  }

  thead {display: table-header-group;}
  tfoot {display: table-footer-group;}
}

.fc-license-message {
  display: none !important;
}

.react-datepicker {
  display: flex;
}

body {
  &.modal-open {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-grab {
  cursor: grab;
}

.modal-open {
  overflow: initial;
}

.badge {
  line-height: 1.2;
}

.switch-slider {
  z-index: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-truncate-auto-width {
  display: flex;
  justify-content: space-between;

  &:after {
    content: "";
    flex: 1;
  }
}

.hoverable {
  &:hover {
    .hover-display {
      display: block !important;
    }
  }

  .hover-display {
    display: none !important;
  }
}

.blink {
  animation: blink 1s linear infinite;
}
@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
  50%{opacity: .5;}
  0%{opacity: 0;}
}

.highlighted-text {
  padding: 0;
  background-color: yellow;
}

footer#footer {
  background: #ebecee;
}