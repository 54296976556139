.card-header {
  padding: .5rem .25rem;
}
.card-body {
  padding: 0.5rem;
}
.tab-content .tab-pane {
  padding: 0.5rem;
}

@media( max-width : 991.98px ) {
  .header-fixed {
    .app-body {
      > .app-body-wrapper {
        padding: 0;

        > .app-body-inner {
          > .view-header {
            padding: .2rem 2px;
            height: 35px;
          }
        }
      }
    }
  }

  .card-body {
    padding: 0.2rem;
  }
  .card-header {
    padding: 0.2rem 0.5rem;

    .btn-sm, .btn-group-sm > .btn {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }

    .switcher-text {
      height: 25px;
    }
  }
  .card-footer {
    padding: 0.2rem 0.5rem;
  }
  .tab-content {
    .tab-pane {
      padding: 0.2rem;
    }
  }
  .modal .modal-header, .modal .modal-body, .modal .modal-footer {
    padding: 0.25rem;
  }
}

.nav-card {
  background-color: #fafafa;
  display: block;
  text-align: center;
  border: 1px solid #D8D8D8;
  &:hover {
    background-color: #efefef;
    text-decoration: none;
  }
}

.breadcrumb {
  margin-bottom: 1rem;
}

.card-body {
  display: flex;
  flex-direction: column;

  >.tab-content {
    flex-grow: 1;
    display: flex;

    >.tab-pane {
      flex-grow: 1;
    }
  }
}

.discounted-price {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 120%;
    left: -10%;
    top: calc(50% - 2px);
    right: 0;
    border-top: 4px solid;
    border-color: inherit;
    color: $danger;

    -webkit-transform:rotate(-12deg);
    -moz-transform:rotate(-12deg);
    -ms-transform:rotate(-12deg);
    -o-transform:rotate(-12deg);
    transform:rotate(-12deg);
  }
}

#CookiebotWidget {
  display: none !important;
  opacity: 0 !important;
  overflow: hidden !important;
}

body.intercom-hidden {
  #intercom-container,
  .intercom-lightweight-app {
    display: none !important;
  }
}

#intercom-container > .intercom-app > div:nth-child(2),
#intercom-container > .intercom-app iframe.intercom-launcher-frame,
.intercom-lightweight-app .intercom-lightweight-app-launcher {
  right: -3px !important;
  bottom: -4px !important;
}

body.faker-data {
  #intercom-container > .intercom-app > div:nth-child(2),
  #intercom-container > .intercom-app iframe.intercom-launcher-frame,
  .intercom-lightweight-app .intercom-lightweight-app-launcher {
    bottom: 55px !important;
  }
}

@media (max-width: 991.98px) {
  #intercom-container > .intercom-app > div:nth-child(2),
  #intercom-container > .intercom-app iframe.intercom-launcher-frame,
  .intercom-lightweight-app .intercom-lightweight-app-launcher {
    right: -3px !important;
    bottom: -7px !important;
  }

  body.faker-data {
    #intercom-container > .intercom-app > div:nth-child(2),
    #intercom-container > .intercom-app iframe.intercom-launcher-frame,
    .intercom-lightweight-app .intercom-lightweight-app-launcher {
      bottom: 35px !important;
    }
  }
}