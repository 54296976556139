// Styles
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Ladda
@import '~ladda/dist/ladda-themeless.min.css';
// Import react-bootstrap-table-next
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// Import toastify
@import "~react-toastify/dist/ReactToastify.min.css";

@import "~react-datepicker/dist/react-datepicker.min.css";

// Import Main styles for this application
@import './scss/style.scss';
