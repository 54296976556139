table.card-control {
  counter-reset: card-control;

  @media (max-width: 575.98px) {
    thead {
      tr {
        th {
          &:not(:first-child) {
            >* {
              display: block;
              max-width: 90px;
            }
          }
        }
      }
    }
  }

  tr {
    .draggable {
      visibility: hidden;
    }

    &:hover {
      .draggable {
        visibility: visible;
      }
    }
  }

  tr.type-header {
    .counter {
      &::before {
        display: flex;
        align-content: center;
        counter-increment: card-control;
        content: counter(card-control) ". ";
      }
    }

    input:not(:focus) {
      background: none;
      border: none;
    }
  }

  thead {
    th {
      &.th-count-1 {
        white-space: normal !important;
        > div {
          min-width: 5vw;
        }
      }
      &.th-count-2 {
        white-space: normal !important;
        > div {
          min-width: 5vw;
        }
      }
      &.th-count-3 {
        white-space: normal !important;
        > div {
          min-width: 5vw;
        }
      }
      &.th-count-4 {
        white-space: normal !important;
        > div {
          min-width: 5vw;
        }
      }

      @media (max-width: 991.98px) {
        &.th-count-1 {
          > div {
            min-width: 8vw;
            max-width: 36vw;
          }
        }
        &.th-count-2 {
          > div {
            min-width: 8vw;
            max-width: 18vw;
          }
        }
        &.th-count-3 {
          > div {
            min-width: 8vw;
            max-width: 14vw;
          }
        }
        &.th-count-4 {
          > div {
            min-width: 8vw;
            max-width: 12vw;
          }
        }
      }

      > div {
        overflow-wrap: break-word;
      }
    }
  }
}